import React, { Component } from "react";
import { getData } from "./../services/fakePictures";
import BigPicture from "./bigpicture";
import Pager from "./pager";
import ImageThumbs from "./imagethumbs";
import _ from "lodash";

const thumbColumnCount = 2;

class ProductPage extends Component {
  state = {
    page: 1,
    currentSet: 1,
    setSize: 8,
    selectedImage: {},
    data: {
      folder: "",
      images: []
    }
  };

  initState() {
    const page = this.props.match.params.id;
    const data = getData(page);
    const selectedImage =
      data && data.images.length > 0 ? data.images[0] : null;
    this.setState({ data, selectedImage, page, currentSet: 1 });
  }

  componentDidMount() {
    this.initState();
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.match.params.id === this.props.match.params.id) return;
    this.initState();
  }

  handleImageSelect = selectedImage => {
    this.setState({ selectedImage });
  };

  handlePreviousSet = () => {
    this.setState({ currentSet: this.state.currentSet - 1 });
  };

  handleNextSet = () => {
    this.setState({ currentSet: this.state.currentSet + 1 });
  };

  getImages = () => {
    const { data, currentSet, setSize } = this.state;
    const startIndex = (currentSet - 1) * setSize;
    const imageSet = _(data.images)
      .slice(startIndex)
      .take(setSize)
      .value();

    return _.chunk(imageSet, thumbColumnCount);
  };

  render() {
    const { page, data, currentSet, setSize, selectedImage } = this.state;
    if (!data) return null;
    const setCount = Math.ceil(data.images.length / setSize);

    const images = this.getImages();
    return (
      <div className="row ">
        <BigPicture page={page} image={selectedImage} />
        <div className="col-3" style={{ paddingLeft: 0 }}>
          <Pager
            currentSet={currentSet}
            setCount={setCount}
            onNext={this.handleNextSet}
            onPrevious={this.handlePreviousSet}
            language={this.props.language}
          />
          <ImageThumbs
            images={images}
            onSelectImage={this.handleImageSelect}
            page={page}
            selectedImage={selectedImage}
          />
        </div>
      </div>
    );
  }
}

export default ProductPage;
