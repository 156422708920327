import React, { Component } from "react";
import { getLangDb } from "../services/langdb";
import {
  withScriptjs,
  withGoogleMap,
  GoogleMap,
  Marker
} from "react-google-maps";

import CarouselSlider from "react-carousel-slider";

class MainPage extends Component {
  render() {
    const MyMapComponent = withScriptjs(
      withGoogleMap(props => (
        <GoogleMap
          defaultZoom={18}
          defaultCenter={{ lat: 48.068467, lng: 22.91082 }}
          defaultMapTypeId={"satellite"}
        >
          {props.isMarkerShown && (
            <Marker position={{ lat: 48.068467, lng: 22.91082 }} />
          )}
        </GoogleMap>
      ))
    );
    //    const langDb = getLangDb(this.props.language);

    let data = {
      autoSliding: {
        items: [
          {
            imgSrc: "../gallery/korlat/0651.JPG"
          },
          {
            imgSrc: "../gallery/main/children.jpg"
          },
          {
            imgSrc: "../gallery/kapu/0612.JPG"
          },
          {
            imgSrc: "../gallery/main/kope.jpg"
          },
          {
            imgSrc: "../gallery/main/sasa.jpg"
          }
        ]
      }
    };
    let manner = {
      autoSliding: { interval: "3s" },
      duration: "2s"
    };

    let buttonSetting = {
      placeOn: "middle-inside",
      hoverEvent: true,
      style: {
        left: {
          height: "50px",
          width: "50px",
          color: "#929393",
          background: "rgba(225, 228, 232, 0.8)",
          borderRadius: "50%"
        },
        right: {
          height: "50px",
          width: "50px",
          color: "#929393",
          background: "rgba(225, 228, 232, 0.8)",
          borderRadius: "50%"
        }
      }
    };
    return (
      <div>
        <CarouselSlider
          slideItems={data.autoSliding.items}
          manner={manner}
          buttonSetting={buttonSetting}
        />
        <MyMapComponent
          isMarkerShown
          googleMapURL="https://maps.googleapis.com/maps/api/js?key=AIzaSyDiquRnawQ-WJcRa2sZMMEI3181andXO8Y"
          loadingElement={<div style={{ height: `100%` }} />}
          containerElement={<div style={{ height: `300px` }} />}
          mapElement={<div style={{ height: `100%` }} />}
          options={function(maps) {
            return { mapTypeId: "satellite" };
          }}
        />
      </div>
    );
  }
}

export default MainPage;
