const galleryFolder = "../gallery/";

const data = [
  {
    page: "1",
    folder: "korlat/",
    images: [
      { id: 1, fileName: "0650.JPG" },
      { id: 2, fileName: "0651.JPG" },
      { id: 3, fileName: "0652.JPG" },
      { id: 4, fileName: "0653.JPG" },
      { id: 5, fileName: "0654.JPG" },
      { id: 6, fileName: "0655.JPG" },
      { id: 7, fileName: "0656.JPG" },
      { id: 8, fileName: "0657.JPG" },
      { id: 9, fileName: "0658.JPG" },
      { id: 10, fileName: "0659.JPG" },
      { id: 11, fileName: "0660.JPG" },
      { id: 12, fileName: "0661.JPG" },
      { id: 13, fileName: "0750.JPG" },
      { id: 14, fileName: "0751.JPG" },
      { id: 15, fileName: "0752.JPG" },
      { id: 16, fileName: "0753.JPG" },
      { id: 17, fileName: "0754.JPG" },
      { id: 18, fileName: "0755.JPG" },
      { id: 19, fileName: "0756.JPG" },
      { id: 20, fileName: "0757.JPG" },
      { id: 21, fileName: "0758.JPG" },
      { id: 22, fileName: "0759.JPG" },
      { id: 23, fileName: "0760.JPG" },
      { id: 24, fileName: "0761.JPG" },
      { id: 25, fileName: "0762.JPG" },
      { id: 26, fileName: "0763.JPG" },
      { id: 27, fileName: "0764.JPG" },
      { id: 28, fileName: "0765.JPG" },
      { id: 29, fileName: "0766.JPG" },
      { id: 30, fileName: "0767.JPG" },
      { id: 31, fileName: "0768.JPG" },
      { id: 32, fileName: "0769.JPG" },
      { id: 33, fileName: "0850.jpg" },
      { id: 34, fileName: "0851.jpg" },
      { id: 35, fileName: "0852.JPG" },
      { id: 36, fileName: "0853.JPG" },
      { id: 37, fileName: "0854.JPG" },
      { id: 38, fileName: "0855.JPG" },
      { id: 39, fileName: "0856.JPG" },
      { id: 40, fileName: "0857.JPG" },
      { id: 41, fileName: "0858.JPG" },
      { id: 42, fileName: "0859.JPG" },
      { id: 43, fileName: "0860.JPG" },
      { id: 44, fileName: "0861.JPG" },
      { id: 45, fileName: "0862.JPG" },
      { id: 46, fileName: "0863.JPG" },
      { id: 47, fileName: "0864.JPG" },
      { id: 48, fileName: "0865.JPG" },
      { id: 49, fileName: "0866.JPG" },
      { id: 50, fileName: "0950.JPG" },
      { id: 51, fileName: "0951.JPG" },
      { id: 52, fileName: "0952.JPG" },
      { id: 53, fileName: "0953.JPG" },
      { id: 54, fileName: "0954.JPG" },
      { id: 55, fileName: "0955.JPG" },
      { id: 56, fileName: "0956.JPG" },
      { id: 57, fileName: "0957.JPG" },
      { id: 58, fileName: "0958.JPG" },
      { id: 59, fileName: "0959.JPG" },
      { id: 60, fileName: "0960.JPG" },
      { id: 61, fileName: "0961.JPG" },
      { id: 62, fileName: "0962.JPG" },
      { id: 63, fileName: "0963.JPG" },
      { id: 64, fileName: "0964.JPG" },
      { id: 65, fileName: "1050.JPG" },
      { id: 66, fileName: "1051.JPG" },
      { id: 67, fileName: "1052.JPG" },
      { id: 68, fileName: "1053.JPG" },
      { id: 69, fileName: "1054.JPG" },
      { id: 70, fileName: "1055.JPG" },
      { id: 71, fileName: "1056.JPG" },
      { id: 72, fileName: "1057.JPG" },
      { id: 73, fileName: "1058.JPG" },
      { id: 74, fileName: "1059.JPG" },
      { id: 75, fileName: "1060.JPG" },
      { id: 76, fileName: "1061.JPG" },
      { id: 77, fileName: "1062.JPG" },
      { id: 78, fileName: "1063.JPG" },
      { id: 79, fileName: "1064.JPG" },
      { id: 80, fileName: "1065.JPG" },
      { id: 81, fileName: "1066.JPG" },
      { id: 82, fileName: "1067.JPG" },
      { id: 83, fileName: "1150.JPG" },
      { id: 84, fileName: "1151.JPG" },
      { id: 85, fileName: "1152.JPG" },
      { id: 86, fileName: "1153.JPG" },
      { id: 87, fileName: "1154.JPG" },
      { id: 88, fileName: "1155.JPG" },
      { id: 89, fileName: "1156.JPG" },
      { id: 90, fileName: "1157.JPG" },
      { id: 91, fileName: "1158.JPG" },
      { id: 92, fileName: "1159.JPG" },
      { id: 93, fileName: "1160.JPG" },
      { id: 94, fileName: "1161.JPG" },
      { id: 95, fileName: "1162.JPG" },
      { id: 96, fileName: "1163.JPG" },
      { id: 97, fileName: "1250.JPG" },
      { id: 98, fileName: "1251.JPG" },
      { id: 99, fileName: "1252.JPG" },
      { id: 100, fileName: "1253.JPG" },
      { id: 101, fileName: "1254.JPG" },
      { id: 102, fileName: "1340.jpg" },
      { id: 103, fileName: "1341.jpg" },
      { id: 104, fileName: "1342.jpg" },
      { id: 105, fileName: "1343.jpg" },
      { id: 106, fileName: "1344.jpg" },
      { id: 107, fileName: "1345.jpg" },
      { id: 108, fileName: "1346.jpg" },
      { id: 109, fileName: "1347.jpg" },
      { id: 110, fileName: "1348.jpg" },
      { id: 111, fileName: "1349.jpg" },
      { id: 112, fileName: "1350.jpg" },
      { id: 113, fileName: "1351.jpg" },
      { id: 114, fileName: "1352.jpg" },
      { id: 115, fileName: "1353.jpg" },
      { id: 116, fileName: "1354.jpg" },
      { id: 117, fileName: "1355.jpg" },
      { id: 118, fileName: "1356.jpg" },
      { id: 119, fileName: "1357.jpg" },
      { id: 120, fileName: "1358.jpg" },
      { id: 121, fileName: "1359.jpg" },
      { id: 122, fileName: "1360.jpg" },
      { id: 123, fileName: "1361.jpg" },
      { id: 124, fileName: "1362.jpg" },
      { id: 125, fileName: "1363.jpg" },
      { id: 126, fileName: "1364.jpg" },
      { id: 127, fileName: "1365.jpg" },
      { id: 128, fileName: "1366.jpg" },
      { id: 129, fileName: "1450.jpg" },
      { id: 130, fileName: "1451.jpg" },
      { id: 131, fileName: "1452.JPG" },
      { id: 132, fileName: "1453.jpg" },
      { id: 133, fileName: "1454.jpg" },
      { id: 134, fileName: "1455.jpg" },
      { id: 135, fileName: "1456.jpg" },
      { id: 136, fileName: "1457.jpg" },
      { id: 137, fileName: "1458.jpg" },
      { id: 138, fileName: "1459.jpg" },
      { id: 139, fileName: "1460.jpg" },
      { id: 140, fileName: "1461.jpg" },
      { id: 141, fileName: "1462.jpg" },
      { id: 142, fileName: "1463.jpg" },
      { id: 143, fileName: "1464.jpg" }
    ]
  },
  {
    page: "2",
    folder: "kapu/",
    images: [
      { id: 1, fileName: "0501.JPG" },
      { id: 2, fileName: "0502.JPG" },
      { id: 3, fileName: "0503.JPG" },
      { id: 4, fileName: "0504.JPG" },
      { id: 5, fileName: "0505.JPG" },
      { id: 6, fileName: "0506.JPG" },
      { id: 7, fileName: "0601.JPG" },
      { id: 8, fileName: "0602.JPG" },
      { id: 9, fileName: "0603.JPG" },
      { id: 10, fileName: "0604.JPG" },
      { id: 11, fileName: "0605.JPG" },
      { id: 12, fileName: "0606.JPG" },
      { id: 13, fileName: "0607.JPG" },
      { id: 14, fileName: "0608.JPG" },
      { id: 15, fileName: "0609.JPG" },
      { id: 16, fileName: "0610.JPG" },
      { id: 17, fileName: "0611.JPG" },
      { id: 18, fileName: "0612.JPG" },
      { id: 19, fileName: "0613.JPG" },
      { id: 20, fileName: "0614.JPG" },
      { id: 21, fileName: "0615.JPG" },
      { id: 22, fileName: "0616.JPG" },
      { id: 23, fileName: "0617.JPG" },
      { id: 24, fileName: "0618.JPG" },
      { id: 25, fileName: "0619.JPG" },
      { id: 26, fileName: "0620.JPG" },
      { id: 27, fileName: "0621.JPG" },
      { id: 28, fileName: "0622.JPG" },
      { id: 29, fileName: "0701.JPG" },
      { id: 30, fileName: "0702.JPG" },
      { id: 31, fileName: "0703.JPG" },
      { id: 32, fileName: "0704.JPG" },
      { id: 33, fileName: "0705.JPG" },
      { id: 34, fileName: "0706.JPG" },
      { id: 35, fileName: "0707.JPG" },
      { id: 36, fileName: "0708.JPG" },
      { id: 37, fileName: "0709.JPG" },
      { id: 38, fileName: "0710.JPG" },
      { id: 39, fileName: "0711.JPG" },
      { id: 40, fileName: "0712.JPG" },
      { id: 41, fileName: "0713.JPG" },
      { id: 42, fileName: "0801.jpg" },
      { id: 43, fileName: "0802.JPG" },
      { id: 44, fileName: "0803.JPG" },
      { id: 45, fileName: "0804.JPG" },
      { id: 46, fileName: "0805.JPG" },
      { id: 47, fileName: "0806.JPG" },
      { id: 48, fileName: "0807.JPG" },
      { id: 49, fileName: "0808.JPG" },
      { id: 50, fileName: "0809.JPG" },
      { id: 51, fileName: "0810.JPG" },
      { id: 52, fileName: "0811.bmp" },
      { id: 53, fileName: "0812.JPG" },
      { id: 54, fileName: "0813.JPG" },
      { id: 55, fileName: "0814.JPG" },
      { id: 56, fileName: "0815.JPG" },
      { id: 57, fileName: "0816.JPG" },
      { id: 58, fileName: "0817.JPG" },
      { id: 59, fileName: "0818.JPG" },
      { id: 60, fileName: "0819.JPG" },
      { id: 61, fileName: "0820.JPG" },
      { id: 62, fileName: "0821.JPG" },
      { id: 63, fileName: "0822.JPG" },
      { id: 64, fileName: "0823.JPG" },
      { id: 65, fileName: "0824.JPG" },
      { id: 66, fileName: "0825.JPG" },
      { id: 67, fileName: "0826.JPG" },
      { id: 68, fileName: "0827.JPG" },
      { id: 69, fileName: "0828.JPG" },
      { id: 70, fileName: "0829.JPG" },
      { id: 71, fileName: "0830.JPG" },
      { id: 72, fileName: "0831.JPG" },
      { id: 73, fileName: "0832.JPG" },
      { id: 74, fileName: "0833.JPG" },
      { id: 75, fileName: "0834.JPG" },
      { id: 76, fileName: "0835.JPG" },
      { id: 77, fileName: "0836.JPG" },
      { id: 78, fileName: "0837.JPG" },
      { id: 79, fileName: "0838.JPG" },
      { id: 80, fileName: "0839.JPG" },
      { id: 81, fileName: "0840.JPG" },
      { id: 82, fileName: "0901.JPG" },
      { id: 83, fileName: "0902.JPG" },
      { id: 84, fileName: "0903.JPG" },
      { id: 85, fileName: "0904.JPG" },
      { id: 86, fileName: "0905.JPG" },
      { id: 87, fileName: "0906.JPG" },
      { id: 88, fileName: "0907.JPG" },
      { id: 89, fileName: "0908.JPG" },
      { id: 90, fileName: "0909.JPG" },
      { id: 91, fileName: "0910.JPG" },
      { id: 92, fileName: "0911.JPG" },
      { id: 93, fileName: "0912.JPG" },
      { id: 94, fileName: "0913.JPG" },
      { id: 95, fileName: "0914.JPG" },
      { id: 96, fileName: "0915.JPG" },
      { id: 97, fileName: "0916.JPG" },
      { id: 98, fileName: "1001.JPG" },
      { id: 99, fileName: "1002.JPG" },
      { id: 100, fileName: "1003.JPG" },
      { id: 101, fileName: "1004.JPG" },
      { id: 102, fileName: "1005.JPG" },
      { id: 103, fileName: "1006.JPG" },
      { id: 104, fileName: "1007.JPG" },
      { id: 105, fileName: "1008.JPG" },
      { id: 106, fileName: "1009.JPG" },
      { id: 107, fileName: "1010.JPG" },
      { id: 108, fileName: "1011.JPG" },
      { id: 109, fileName: "1012.JPG" },
      { id: 110, fileName: "1013.JPG" },
      { id: 111, fileName: "1101.JPG" },
      { id: 112, fileName: "1102.JPG" },
      { id: 113, fileName: "1103.JPG" },
      { id: 114, fileName: "1104.JPG" },
      { id: 115, fileName: "1105.JPG" },
      { id: 116, fileName: "1106.JPG" },
      { id: 117, fileName: "1107.JPG" },
      { id: 118, fileName: "1108.JPG" },
      { id: 119, fileName: "1109.JPG" },
      { id: 120, fileName: "1110.JPG" },
      { id: 121, fileName: "1111.JPG" },
      { id: 122, fileName: "1112.JPG" },
      { id: 123, fileName: "1113.JPG" },
      { id: 124, fileName: "1114.JPG" },
      { id: 125, fileName: "1115.JPG" },
      { id: 126, fileName: "1116.JPG" },
      { id: 127, fileName: "1117.JPG" },
      { id: 128, fileName: "1118.JPG" },
      { id: 129, fileName: "1119.JPG" },
      { id: 130, fileName: "1120.JPG" },
      { id: 131, fileName: "1121.JPG" },
      { id: 132, fileName: "1201.JPG" },
      { id: 133, fileName: "1202.JPG" },
      { id: 134, fileName: "1203.JPG" },
      { id: 135, fileName: "1204.JPG" },
      { id: 136, fileName: "1205.JPG" },
      { id: 137, fileName: "1206.JPG" },
      { id: 138, fileName: "1207.JPG" },
      { id: 139, fileName: "1208.JPG" },
      { id: 140, fileName: "1209.JPG" },
      { id: 141, fileName: "1301.jpg" },
      { id: 142, fileName: "1302.jpg" },
      { id: 143, fileName: "1303.jpg" },
      { id: 144, fileName: "1304.jpg" },
      { id: 145, fileName: "1305.jpg" },
      { id: 146, fileName: "1306.jpg" },
      { id: 147, fileName: "1307.jpg" },
      { id: 148, fileName: "1308.jpg" },
      { id: 149, fileName: "1309.jpg" },
      { id: 150, fileName: "1310.jpg" },
      { id: 151, fileName: "1311.jpg" },
      { id: 152, fileName: "1312.jpg" },
      { id: 153, fileName: "1313.jpg" },
      { id: 154, fileName: "1314.jpg" },
      { id: 155, fileName: "1315.jpg" },
      { id: 156, fileName: "1316.jpg" },
      { id: 157, fileName: "1317.jpg" },
      { id: 158, fileName: "1318.jpg" },
      { id: 159, fileName: "1319.jpg" },
      { id: 160, fileName: "1320.jpg" },
      { id: 161, fileName: "1321.jpg" },
      { id: 162, fileName: "1322.jpg" },
      { id: 163, fileName: "1323.jpg" },
      { id: 164, fileName: "1324.jpg" },
      { id: 165, fileName: "1325.jpg" },
      { id: 166, fileName: "1401.JPG" },
      { id: 167, fileName: "1402.jpg" },
      { id: 168, fileName: "1403.jpg" },
      { id: 169, fileName: "1404.JPG" },
      { id: 170, fileName: "1405.JPG" },
      { id: 171, fileName: "1406.JPG" },
      { id: 172, fileName: "1407.JPG" },
      { id: 173, fileName: "1408.jpg" },
      { id: 174, fileName: "1409.jpg" },
      { id: 175, fileName: "1410.jpg" },
      { id: 176, fileName: "1411.jpg" },
      { id: 177, fileName: "1412.jpg" },
      { id: 178, fileName: "1413.jpg" },
      { id: 179, fileName: "1414.jpg" },
      { id: 180, fileName: "1415.jpg" },
      { id: 181, fileName: "1416.jpg" },
      { id: 182, fileName: "1417.jpg" },
      { id: 183, fileName: "1418.jpg" },
      { id: 184, fileName: "1419.jpg" },
      { id: 185, fileName: "1420.jpg" },
      { id: 186, fileName: "1421.jpg" }
    ]
  },
  {
    page: "3",
    folder: "eresz/",
    images: [
      { id: 1, fileName: "0770.JPG" },
      { id: 2, fileName: "0771.JPG" },
      { id: 3, fileName: "0772.JPG" },
      { id: 4, fileName: "0870.JPG" },
      { id: 5, fileName: "0970.jpg" },
      { id: 6, fileName: "0971.JPG" },
      { id: 7, fileName: "0972.JPG" },
      { id: 8, fileName: "0973.JPG" },
      { id: 9, fileName: "0974.JPG" },
      { id: 10, fileName: "0975.JPG" },
      { id: 11, fileName: "0976.JPG" },
      { id: 12, fileName: "1070.JPG" },
      { id: 13, fileName: "1071.JPG" },
      { id: 14, fileName: "1072.JPG" },
      { id: 15, fileName: "1073.JPG" },
      { id: 16, fileName: "1170.JPG" },
      { id: 17, fileName: "1171.JPG" },
      { id: 18, fileName: "1172.JPG" },
      { id: 19, fileName: "1173.JPG" },
      { id: 20, fileName: "1174.JPG" },
      { id: 21, fileName: "1175.JPG" },
      { id: 22, fileName: "1176.JPG" },
      { id: 23, fileName: "1177.JPG" },
      { id: 24, fileName: "1178.JPG" },
      { id: 25, fileName: "1179.JPG" },
      { id: 26, fileName: "1270.JPG" },
      { id: 27, fileName: "1370.jpg" },
      { id: 28, fileName: "1371.jpg" },
      { id: 29, fileName: "1372.jpg" },
      { id: 30, fileName: "1471.JPG" },
      { id: 31, fileName: "1472.jpg" }
    ]
  },
  {
    page: "4",
    folder: "egyeb/",
    images: [
      { id: 1, fileName: "0580.JPG" },
      { id: 2, fileName: "0680.JPG" },
      { id: 3, fileName: "0681.JPG" },
      { id: 4, fileName: "0682.JPG" },
      { id: 5, fileName: "0683.jpg" },
      { id: 6, fileName: "0684.jpg" },
      { id: 7, fileName: "0685.JPG" },
      { id: 8, fileName: "0686.jpg" },
      { id: 9, fileName: "0687.JPG" },
      { id: 10, fileName: "0688.JPG" },
      { id: 11, fileName: "0689.JPG" },
      { id: 12, fileName: "0690.JPG" },
      { id: 13, fileName: "0691.JPG" },
      { id: 14, fileName: "0692.JPG" },
      { id: 15, fileName: "0780.JPG" },
      { id: 16, fileName: "0781.JPG" },
      { id: 17, fileName: "0783.JPG" },
      { id: 18, fileName: "0784.JPG" },
      { id: 19, fileName: "0880.JPG" },
      { id: 20, fileName: "0881.JPG" },
      { id: 21, fileName: "0882.JPG" },
      { id: 22, fileName: "0883.JPG" },
      { id: 23, fileName: "0884.JPG" },
      { id: 24, fileName: "0885.JPG" },
      { id: 25, fileName: "0886.JPG" },
      { id: 26, fileName: "0980.JPG" },
      { id: 27, fileName: "0981.JPG" },
      { id: 28, fileName: "0982.JPG" },
      { id: 29, fileName: "0983.JPG" },
      { id: 30, fileName: "0984.JPG" },
      { id: 31, fileName: "0985.JPG" },
      { id: 32, fileName: "0986.JPG" },
      { id: 33, fileName: "1080.JPG" },
      { id: 34, fileName: "1081.JPG" },
      { id: 35, fileName: "1082.JPG" },
      { id: 36, fileName: "1083.JPG" },
      { id: 37, fileName: "1084.JPG" },
      { id: 38, fileName: "1180.JPG" },
      { id: 39, fileName: "1181.JPG" },
      { id: 40, fileName: "1182.JPG" },
      { id: 41, fileName: "1183.JPG" },
      { id: 42, fileName: "1184.JPG" },
      { id: 43, fileName: "1185.JPG" },
      { id: 44, fileName: "1186.JPG" },
      { id: 45, fileName: "1187.JPG" },
      { id: 46, fileName: "1188.JPG" },
      { id: 47, fileName: "1189.JPG" },
      { id: 48, fileName: "1190.JPG" },
      { id: 49, fileName: "1192.JPG" },
      { id: 50, fileName: "1280.JPG" },
      { id: 51, fileName: "1281.JPG" },
      { id: 52, fileName: "1282.JPG" },
      { id: 53, fileName: "1283.JPG" },
      { id: 54, fileName: "1284.JPG" },
      { id: 55, fileName: "1285.JPG" },
      { id: 56, fileName: "1286.JPG" },
      { id: 57, fileName: "1287.JPG" },
      { id: 58, fileName: "1288.JPG" },
      { id: 59, fileName: "1289.JPG" },
      { id: 60, fileName: "1290.JPG" },
      { id: 61, fileName: "1291.JPG" },
      { id: 62, fileName: "1293.JPG" },
      { id: 63, fileName: "1294.JPG" },
      { id: 64, fileName: "1295.JPG" },
      { id: 65, fileName: "1380.jpg" },
      { id: 66, fileName: "1381.jpg" },
      { id: 67, fileName: "1382.jpg" },
      { id: 68, fileName: "1383.jpg" },
      { id: 69, fileName: "1384.jpg" },
      { id: 70, fileName: "1385.jpg" },
      { id: 71, fileName: "1386.jpg" },
      { id: 72, fileName: "1480.jpg" },
      { id: 73, fileName: "1481.JPG" },
      { id: 74, fileName: "1482.jpg" },
      { id: 75, fileName: "1483.jpg" },
      { id: 76, fileName: "1484.JPG" },
      { id: 77, fileName: "1485.jpg" },
      { id: 78, fileName: "1486.jpg" },
      { id: 79, fileName: "1487.jpg" },
      { id: 80, fileName: "1488.jpg" },
      { id: 81, fileName: "1489.jpg" },
      { id: 82, fileName: "1490.jpg" },
      { id: 83, fileName: "1491.jpg" }
    ]
  }
];

export function getData(page) {
  const index = data.findIndex(p => p.page === page);
  return index >= 0 ? data[index] : null;
}

export function getImageUrl(page, id) {
  const data = getData(page);
  if (!data) return null;

  const { folder, images } = data;
  const index = images.findIndex(i => i.id === id);
  return index >= 0 ? galleryFolder + folder + images[index].fileName : null;
}

export function getImageSubtitle(image) {
  if (!image.fileName) return null;
  return image.fileName.substring(0, image.fileName.length - 4);
}
