import React from "react";
import { Image } from "react-bootstrap";
import { getImageUrl } from "./../services/fakePictures";

const ImageThumbs = ({ page, images, onSelectImage, selectedImage }) => {
  return (
    <table className="table">
      <tbody>
        {images.map(imgs => (
          <tr key={imgs[0].id}>
            {imgs.map(img => (
              <th key={img.id} scope="row" style={{ padding: 9, border: 0 }}>
                <Image
                  className={img.id === selectedImage.id ? "active" : ""}
                  onClick={() => onSelectImage(img)}
                  style={{
                    width: 120,
                    height: 120,
                    cursor: "pointer"
                  }}
                  src={getImageUrl(page, img.id)}
                />
              </th>
            ))}
          </tr>
        ))}
      </tbody>
    </table>
  );
};

export default ImageThumbs;
