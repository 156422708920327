const data = [
  {
    language: "HU",
    db: {
      banisters: "Korlátok",
      gatesFences: "Kapuk/Kerítések",
      canopies: "Előtetők",
      others: "Egyéb",
      previous: "Előző",
      next: "Következő",
      subtitle: "Kovácsoltvas munkák Kárpátalján",
      greeting: ""
      //"Minden Kedves Ügyfelének Boldog, Békés Karácsonyt és Sikeres Új Évet Kíván a Kovácsda Csapata!"
    }
  },
  {
    language: "GB",
    db: {
      banisters: "Banisters",
      gatesFences: "Gates/Fences",
      canopies: "Canopies",
      others: "Others",
      previous: "Previous",
      next: "Next",
      subtitle: "Wrought iron works in Transcarpathia",
      greeting: ""
      //"Happy, Peaceful Christmas and Successful New Year For All Our Dear Customers. Kovácsda Team!"
    }
  },
  {
    language: "UA",
    db: {
      banisters: "Oгородження",
      gatesFences: "Bорота/Паркан",
      canopies: "Пологи",
      others: "Iнші",
      previous: "Попередній",
      next: "Далі",
      subtitle: "Кованого заводу працює на Закарпатті",
      greeting: ""
      // "Щасливий, мирний Різдво та успішний Новий Рік для всіх наших дорогих клієнтів. Команда Коватшда!"
    }
  }
];

export function getLangDb(language) {
  const index = data.findIndex(item => item.language === language);
  return index >= 0 ? data[index].db : null;
}
