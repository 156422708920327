import React from "react";
import { getImageUrl, getImageSubtitle } from "./../services/fakePictures";
import { Image } from "react-bootstrap";

const BigPicture = ({ page, image }) => {
  const src = getImageUrl(page, image.id);
  const subtitle = getImageSubtitle(image);

  return (
    <div className="col" style={{ paddingRight: 0 }}>
      <Image style={{ width: "100%", height: "100%" }} src={src} />
      <h1 className="pict-sub">{subtitle}</h1>
    </div>
  );
};

export default BigPicture;
