import React from "react";
import { getLangDb } from "../services/langdb";

const Pager = ({ currentSet, setCount, onNext, onPrevious, language }) => {
  console.log(language);
  const langDb = getLangDb(language);

  return (
    <nav style={{ display: "inline-block" }}>
      <ul className="pagination m-2">
        <li className={currentSet === 1 ? "page-item disabled" : "page-item"}>
          <a
            className="page-link"
            onClick={() => onPrevious()}
            style={{ width: 130 }}
          >
            &laquo; {langDb.previous}
          </a>
        </li>
        <li
          className={
            currentSet === setCount ? "page-item disabled" : "page-item"
          }
        >
          <a
            className="page-link"
            onClick={() => onNext()}
            style={{ width: 130 }}
          >
            {langDb.next} &raquo;
          </a>
        </li>
      </ul>
    </nav>
  );
};

export default Pager;
