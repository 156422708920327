import React from "react";
import { Link, NavLink } from "react-router-dom";
import ReactFlagsSelect from "react-flags-select";
import "react-flags-select/css/react-flags-select.css";
import { getLangDb } from "../services/langdb";

const NavBar = ({ language, onSelectLanguage }) => {
  const langDb = getLangDb(language);

  return (
    <div className="row">
      <div className="col" style={{ paddingRight: "0" }}>
        <nav className="navbar navbar-expand-lg navbar-light bg-light">
          <Link className="navbar-brand" to="/">
            Kovácsda
          </Link>
          <NavLink className="nav-item nav-link" to="/products/1">
            {langDb.banisters}
          </NavLink>
          <NavLink className="nav-item nav-link" to="/products/2">
            {langDb.gatesFences}
          </NavLink>
          <NavLink className="nav-item nav-link" to="/products/3">
            {langDb.canopies}
          </NavLink>
          <NavLink className="nav-item nav-link" to="/products/4">
            {langDb.others}
          </NavLink>
        </nav>
      </div>
      <div className="col-md-1" style={{ paddingLeft: "0" }}>
        <nav
          className="navbar navbar-expand-lg navbar-light bg-light"
          style={{ paddingBottom: 0 }}
        >
          <ReactFlagsSelect
            defaultCountry="HU"
            countries={["HU", "UA", "GB"]}
            customLabels={{ HU: "Magyar", GB: "English", UA: "Yкраїнська" }}
            showSelectedLabel={false}
            selectedSize={14.8}
            onSelect={onSelectLanguage}
          />
        </nav>
      </div>
    </div>
  );
};

export default NavBar;
