import React from "react";
import { Image } from "react-bootstrap";
import { getLangDb } from "../services/langdb";

const KovacsdaLogo = props => {
  const langDb = getLangDb(props.language);

  return (
    <div className="logopicture">
      <Image
        style={{ width: "100%", height: "100%", paddingBottom: 10 }}
        src="/kovacsda_logo.jpg"
      />
      <div>
        <h1 id="logo">KOVÁCSDA</h1>
        <span id="sublogo">{langDb.subtitle}</span>
      </div>
    </div>
  );
};

export default KovacsdaLogo;
