import React, { Component } from "react";
import { Route, Switch, Redirect } from "react-router-dom";
import NavBar from "./components/navbar";
import MainPage from "./components/mainpage";
import ProductPage from "./components/productpage";
import KovacsdaLogo from "./components/kovacsdalogo";

import "./App.css";

class App extends Component {
  state = {
    language: "HU"
  };

  handleSelectLanguage = language => {
    this.setState({ language });
  };

  render() {
    const { language } = this.state;
    return (
      <React.Fragment>
        <main className="container">
          <div className="App">
            <NavBar
              language={language}
              onSelectLanguage={this.handleSelectLanguage}
            />
            <KovacsdaLogo language={language} />
            <Switch>
              <Route
                path="/main"
                render={props => <MainPage language={language} {...props} />}
              />
              <Route
                path="/products/:id"
                render={props => <ProductPage language={language} {...props} />}
              />
              <Redirect from="/" to="/main" exact />
            </Switch>
          </div>
        </main>
      </React.Fragment>
    );
  }
}

export default App;
